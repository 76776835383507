/*** Calendar ***/
#analysis .Calendar {
	/* margin-left: auto;
    margin-right: auto; */
	width: 500px;
	color: #333;
	font-weight: 500;
	padding: 10px;
	/* background: white; */
}

#analysis .Calendar header {
	position: relative;
	overflow: hidden;
	padding: 0;
	color: #333;
}

#analysis .Calendar nav {
	margin-top: -10px;
	text-align: center;
}

#analysis .Calendar .btn {
	box-sizing: content-box;
	width: 1rem;
	height: 2.8rem;
	line-height: 2.8rem;
	background: none;
	/* border: 1px solid #333; */
	color: #333;
	border-radius: 0.5rem;
	font-size: 1.2rem;
	font-family: 'Nanum Gothic', sans-serif;
	outline: none;
	cursor: pointer;
	font-weight: 900;
}

#analysis .Calendar .btn:active {
	background: rgba(94, 94, 94, 0.466);
	color: #fff;
	/* border: 1px solid rgba(94, 94, 94, 0); */
}

#analysis .Calendar .todayBtn {
	width: 6rem;
}

#analysis .Calendar .main {
	display: flex;
	flex-flow: row wrap;
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	width: 100%;
	/* height: 48rem; */
	box-sizing: content-box;
}
#analysis .Calendar .days {
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-bottom: solid 1px #000000;
	text-align: center;
	width: 100%;
	height: 32px;
	box-sizing: content-box;
	/* background: #4fc3f7; */
}

#analysis .Calendar .day {
	border-right: 1px solid #ddd;
	display: inline-block;
	width: calc(100% / 7);
	text-align: center;
	padding: 0.4rem 0.8rem;
	font-size: 0.9rem;
	font-weight: 700;
}

#analysis .Calendar .dates {
	position: relative;
	border-bottom: 1px solid #ddd;
	border-right: 1px solid #ddd;
	box-sizing: border-box;
	display: inline-block;
	width: calc(100% / 7);
	height: 84px;
	/* padding: 1rem; */
	font-size: 1rem;
	font-weight: 600;
	line-height: 1rem;
	text-align: center;
}

#analysis .Calendar .c_after {
	color: #6c6c6c;
	opacity: 0.6;
}

#analysis .Calendar .day:nth-child(7n + 1),
.dates:nth-child(7n + 1) .c_date {
	color: rgb(209, 35, 35);
}

#analysis .Calendar .day:nth-child(7n + 7),
.dates:nth-child(7n + 7) .c_date {
	color: rgb(35, 112, 212);
}

#analysis .Calendar .dates.others {
	color: rgba(200, 200, 200, 0.4);
}

#analysis .Calendar b {
	font-weight: inherit;
}

#analysis .Calendar .today .c_date {
	background: #135de6;
	color: #fff;
	font-weight: bold;
}

#analysis .Calendar .today > .c_date {
	/* border: 1px solid rgb(0, 0, 0); */
}

#analysis .Calendar .dates > .c_date {
	width: 22px;
	height: 22px;
	text-align: left;
	padding-left: 4px;
}

#analysis .Calendar .dates > .c_count {
	font-size: 0.8rem;
	font-weight: 400;
}

#analysis .Calendar .dates > .c_pointer {
	cursor: pointer;
}

#analysis .Calendar .dates > .b_cnt {
	position: absolute;
	top: 0;
	right: 0;
	background: #6c6c6c;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	color: white;
	text-align: center;
	padding: 2px;
}

#analysis .Calendar .dates > .c_cnt {
	position: absolute;
	top: 0;
	right: 0;
	background: red;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	color: white;
	text-align: center;
	padding: 2px;
}

#analysis .Calendar .dates > div p {
	display: inline-block;
	cursor: pointer;
	width: 40px;
	height: 40px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 10rem;
	background: rgb(0, 176, 80);
	color: #fff;
	text-align: center;
	margin-top: 6px !important;
}

#analysis .Calendar .dates .seqs {
	background: rgb(255, 0, 0);
}

#analysis .Calendar .dates div .seqs {
	margin-right: 10px;
}

#analysis .Calendar .click {
	padding: 10px;
	background: rgb(252, 196, 19);
	color: #fff;
}

@media (max-width: 768px) {
	#analysis .Calendar {
		width: 100%;
	}
	#analysis .Calendar .dates {
		height: 50px;
	}
	#analysis .Calendar .dates > .c_date {
		width: 16px;
		height: 16px;
		font-size: 0.7rem;
		padding: 0px 4px;
		margin: 0;
	}
	#analysis .Calendar .dates > .c_total {
		font-size: 0.7rem;
		margin: 0;
	}
	#analysis .Calendar .dates > .c_count {
		font-size: 0.7rem;
		margin: 0;
	}
}

@media (max-width: 1279px) {
	#analysis .Chart,
	.Calendar {
		float: none;
		margin: auto;
	}
}
