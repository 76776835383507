/* Bootstrap 때문에 font 작아짐 => 복구 */
html {
	font-size: 1rem !important;
	font-weight: 500 !important;
}

/* 스터디 공간관리 테이블 */
#main th,
#main tbody td {
	text-align: center !important;
	vertical-align: middle;
}

tbody td, th {
	text-align: center !important;
	vertical-align: middle !important;
}

.study_place_bs .react-bs-table {
	background-color: white;
}

.select_kind {
	margin-left: 20px !important;
}

#point label {
	height: 25px;
}

#customDialog_btn {
	padding: 0 0 !important;
}

.hidden_ {
	display: none !important;
	visibility: hidden !important;
}

/* 사이드 메뉴 기본정보 수정 메뉴 간격 조절 */
#sideMenu_place > div > a button {
	text-align: left;
}
#sideMenu_place > div > a button span span:first-child {
	padding-left: 40px;
}
/* 사이드 메뉴 => 자식메뉴 간격 조절 */
#sideMenu > ul > li > div > div > div > ul > a > button > span {
	margin-left: 30px;
}

#placeInfo {
	width: 100%;
	height: 80px;
	color: white;
	text-align: center;
	display: table;
}
#placeInfo h5 {
	display: table-cell;
	vertical-align: middle;
}

.notchedOutline {
	border: solid 1px !important;
	border-color: black !important;
}

#ReactGridGallery {
	max-width: 430px;
}

.ReactGridGallery_tile-icon-bar > div {
	float: right !important;
}

/* 좌석 상세보기 */
.seat_span {
	background: #1abc9c;
	border-radius: 20px;
	color: white;
	width: 80px;
	padding: 3px 10px;
	text-align: center;
	margin-top: 2px;
	margin-right: 10px;
	float: right;
}

.user_span {
	background: #d3d3d3;
	border-radius: 20px;
	color: white;
	width: 64px;
	padding: 3px 10px;
	text-align: center;
	float: right;
}

.seat_row_span {
	text-align: left;
	margin-top: 20px;
	width: 320px;
}

.dialogCancle {
	width: 20px;
	height: 20px;
	position: absolute;
	cursor: pointer;
	right: 5px;
	top: 5px;
	opacity: 1;
}

.cancleIcon {
	width: 20px;
	height: 20px;
	position: absolute;
	cursor: pointer;
	right: 5px;
	top: 12px;
	opacity: 1;
}

.extension_textarea {
	resize: none;
	margin: 20px 0px 20px 20px;
	width: 80px;
}

.add_textarea {
	resize: none;
	margin: 20px 0px 0px 20px;
	width: 280px;
}

/* 좌석배치도 전체화면 배경 */
::backdrop {
	background-color: white;
}
/* postfix 크기 조절 */
#timeAdornment p {
	width: 30px;
}
#payAdornment p {
	width: 16px;
}

/* Custom Dilaog 설정 */
.customDialog > div:nth-child(2) {
	box-shadow: none;
	border-radius: 12px;
	border: 2px solid black;
}

/* 다이얼로그 행 넓이*/
.dialog_row {
	width: 380px;
}
.room_dialog_row {
	width: 380px;
}
.room_dialog_title {
	text-align: left;
	width: 260px;
	font-size: 26px;
	font-weight: 700;
}
.dialog_select {
	width: 280px;
}
.dialog_btn {
	width: 140px;
}
.dialog_btn3 {
	width: 100px;
}
.room_dialog_btn {
	width: 170px;
}
.dialog_btn_full {
	width: 280px;
}

@media (max-width: 468px) {
	#toolbar_systemBtn {
		width: 40px !important;
	}
	#toolbar_memberBtn {
		width: 40px !important;
	}
	#toolbar_system {
		display: none;
	}
	#toolbar_member {
		display: none;
	}
}
@media (max-width: 768px) {
	.customDialog > div:nth-child(2) {
		margin: 8px;
	}
	#toolbar_systemBtn {
		width: 100px;
		border-radius: 10px;
	}
	#toolbar_memberBtn {
		width: 100px;
		border-radius: 10px;
	}
	#toolbar_managerId {
		font-size: 14px;
	}
	#toolbar_system {
		font-size: 14px;
	}
	#toolbar_member {
		font-size: 14px;
	}
	.dialog_row,
	.room_dialog_row {
		width: 300px;
	}
	.room_dialog_title {
		font-size: 22px;
	}
	.dialog_select {
		width: 190px;
	}
	.dialog_btn,
	.dialog_btn3,
	.room_dialog_btn {
		width: 90px;
	}
	.dialog_btn_full {
		width: 230px;
	}
	.dialog_btn_locker {
		width: 210px;
	}
	.seat_row_span {
		font-size: 16px;
	}
	.pagination > li > a,
	.pagination > li > span {
		padding: 4px 8px;
		font-size: 12px;
	}
	#dashboard_deskusage .react-bs-table-pagination .col-xs-6,
	#dashboard_sales .react-bs-table-pagination .col-xs-6 {
		width: 100%;
	}
	.sales_datesediv {
		padding: 0 !important;
	}
	.sales_dateselect {
		width: 120px !important;
	}
	.sales_dateselect input {
		width: 120px;
		font-size: 12px;
	}
	.sales_datespan {
		width: 20px !important;
		font-size: 20px !important;
	}
	.sales_datebtn {
		min-width: 50px !important;
		padding: 0 !important;
		margin-left: 10px !important;
	}
}
@media (min-width: 769px) {
	#toolbar_systemBtn {
		width: 110px;
		border-radius: 10px;
	}
	#toolbar_memberBtn {
		width: 110px;
		border-radius: 10px;
	}
	#toolbar_managerId {
		font-size: 16px;
	}
	#toolbar_system {
		font-size: 16px;
	}
	#toolbar_member {
		font-size: 16px;
	}
}
