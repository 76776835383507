.main {
	margin-left: 20px;
	margin-right: 20px;
	justify-content: center;
	margin-top: 5%;
	@media (min-width: 1400px) {
		margin-left: 15%;
		margin-right: 15%;
	}
}

.button_div {
	justify-content: center;
	align-content: center;
}
.detail_btn {
	width: 60px;
	height: 26px;
	padding: 0px;
	margin: 0px 5px !important;
	min-width: 0 !important;
	min-height: 0 !important;
}

th {
	text-align: center;
}

.post_btn {
	margin-top: 10px;
	margin-left: 10px;
	font-size: 9px;
	padding: 3px;
	padding: 3px;
	font-size: 11px;
}

.portal-map-page-wrapper {
	position: relative;
	height: 300px;
	@media (min-width: 600px) {
		height: 100%;
	}
}

.portal-map-cards {
	flex-direction: column;
	flex-wrap: nowrap;
	box-sizing: border-box;
	display: flex;
	flex: 1 1 100%;
	max-width: 100%;
	position: relative;
	top: 0;
	z-index: 1;
	padding: 16px;
	order: 2;
	margin-top: -100px;
	@media (min-width: 600px) {
		position: absolute;
		order: 1;
		height: 100%;
		margin-top: 0;
	}
}

.portal-map-card-list {
	overflow: auto;
	height: 228px;
	cursor: pointer;
}

.portal-map-card-contact {
	padding: 24px;
	position: relative;
	overflow: auto;
}

.portal-maps-contact-detail-card {
	height: 200px;
	padding: 24px;
	position: relative;
	box-sizing: border-box;
	overflow: auto;
	@media (min-width: 600px) {
		animation: portal-maps-contact-detail-card 0.3s ease-out 1;
	}
}

.portal-maps-contact-detail-card__explainer {
	text-align: center;
	padding-top: 80px;
}

// pulse icon
.portal-maps-contact-detail-card__pin {
	width: 24px;
	height: 24px;
	border-radius: 50% 50% 50% 0;
	position: absolute;
	transform: rotate(-45deg);
	left: 50%;
	top: 55px;
	margin: -12px 0 0 -12px;
	animation-name: portal-maps-contact-detail-card__pin;
	animation-fill-mode: both;
	animation-duration: 1s;
	z-index: 2;
	&:after {
		content: '';
		width: 8px;
		height: 8px;
		margin: 8px 0 0 8px;
		position: absolute;
		border-radius: 50%;
	}
}
.income-toolbar{
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  & div:last-child{
    // height: 35px;
    margin: 0;
    & input{
      z-index: 0;
      padding: 5px 10px;
    }
    // padding: 8px 16px;
    // font-size: 0.875rem;
    // border-radius: 4px;
    // border: 1px solid rgba(0,0,0,0.23);
  }
}

.search-bar{
  display: flex;
  align-items: center;
}

.portal-maps-contact-detail-card__pulse {
	border-radius: 50%;
	height: 14px;
	width: 14px;
	position: absolute;
	left: 50%;
	top: 55px;
	margin: 11px 0px 0px -7px;
	transform: rotateX(55deg);
	z-index: 1;
	&:after {
		content: '';
		border-radius: 50%;
		height: 40px;
		width: 40px;
		position: absolute;
		margin: -13px 0 0 -13px;
		animation: portal-maps-contact-detail-card__pulse 1s ease-out;
		animation-iteration-count: infinite;
		opacity: 0;
		animation-delay: 1.1s;
	}
}

@keyframes portal-maps-contact-detail-card {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes portal-maps-contact-detail-card__pulse {
	0% {
		transform: scale(0.1, 0.1);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: scale(1.2, 1.2);
		opacity: 0;
	}
}

@keyframes portal-maps-contact-detail-card__pin {
	0% {
		opacity: 0;
		transform: translateY(-1000px) rotate(-45deg);
	}
	60% {
		opacity: 1;
		transform: translateY(30px) rotate(-45deg);
	}
	80% {
		transform: translateY(-10px) rotate(-45deg);
	}
	100% {
		transform: translateY(0) rotate(-45deg);
	}
}
