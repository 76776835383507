/*** Calendar ***/
#studyroom .Calendar header {
	position: relative;
	overflow: hidden;
	padding: 0;
	color: #333;
}

#studyroom .Calendar nav {
	margin-top: -10px;
	text-align: center;
}

#studyroom .Calendar .btn {
	box-sizing: content-box;
	width: 1rem;
	height: 2.8rem;
	line-height: 2.8rem;
	background: none;
	color: #333;
	border-radius: 0.5rem;
	font-size: 1.2rem;
	font-family: 'Nanum Gothic', sans-serif;
	outline: none;
	cursor: pointer;
	font-weight: 900;
}

#studyroom .Calendar .btn:active {
	background: rgba(94, 94, 94, 0.466);
	color: #fff;
}

#studyroom .Calendar .todayBtn {
	width: 6rem;
}

#studyroom .Calendar .main {
	display: flex;
	flex-flow: row wrap;
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	width: 100%;
	box-sizing: content-box;
}
#studyroom .Calendar .days {
	border-top: 1px solid #ddd;
	border-left: 1px solid #ddd;
	border-bottom: solid 1px #000000;
	text-align: center;
	width: 100%;
	height: 32px;
	box-sizing: content-box;
}

#studyroom .Calendar .day {
	border-right: 1px solid #ddd;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: calc(100% / 7);
	height: 100%;
	text-align: center;
	font-size: 0.9rem;
	font-weight: 700;
}

#studyroom .Calendar .dates {
	position: relative;
	border-bottom: 1px solid #ddd;
	border-right: 1px solid #ddd;
	box-sizing: border-box;
	display: inline-block;
	width: calc(100% / 7);
	height: 80px;
	padding: 1rem;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1rem;
	text-align: center;
}

#studyroom .Calendar .c_after {
	color: #6c6c6c;
	opacity: 0.6;
}

#studyroom .Calendar .day:nth-child(7n + 1),
.dates:nth-child(7n + 1) .c_date {
	color: rgb(209, 35, 35);
}

#studyroom .Calendar .day:nth-child(7n + 7),
.dates:nth-child(7n + 7) .c_date {
	color: rgb(35, 112, 212);
}

#studyroom .Calendar .dates.others {
	color: rgba(200, 200, 200, 0.4);
}

#studyroom .Calendar b {
	font-weight: inherit;
}

#studyroom .Calendar .today .c_date {
	background: #135de6;
	color: #fff;
	font-weight: bold;
}

#studyroom .Calendar .c_pointer {
	cursor: pointer;
}

#studyroom .Calendar .dates > .b_cnt {
	background: #6c6c6c;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	color: white;
	text-align: center;
	padding: 2px;
}

#studyroom .Calendar .dates > .c_cnt {
	background: #f50057;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	color: white;
	text-align: center;
	padding: 2px;
}

#studyroom .Calendar .dates > div p {
	display: inline-block;
	cursor: pointer;
	width: 40px;
	height: 40px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 10rem;
	background: rgb(0, 176, 80);
	color: #fff;
	text-align: center;
	margin-top: 6px !important;
}

#studyroom .Calendar .dates .seqs {
	background: rgb(255, 0, 0);
}

#studyroom .Calendar .dates div .seqs {
	margin-right: 10px;
}

#studyroom .Calendar .dates.click {
	border: solid 1px #135de6 !important;
}

#studyroom .Calendar .dates {
	position: relative;
	border-bottom: 1px solid #ddd;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	height: 80px;
	padding: 0px;
	font-weight: 600;
	line-height: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

#studyroom .Calendar .dates > .c_date {
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	width: 26px;
	height: 26px;
	font-size: 0.8rem;
	padding: 6px 5px;
}

#studyroom .Calendar .dates > .b_cnt,
#studyroom .Calendar .dates > .c_cnt {
	width: auto;
	height: auto;
	border-radius: 3px;
	color: #fff;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 4px 4px;
	padding: 4px 10px;
}

#studyroom .Calendar .dates .c_txt {
	vertical-align: middle;
	font-size: 0.8rem;
}

/*** TimeTalble ***/
#studyroom .timeTalble {
	text-align: center;
	padding: 14px;
	/* background: white;
	border-radius: 10px;
	border: solid 1px #c1c1c1; */
	overflow: auto;
}

#studyroom .timeTalble table {
	border: 1px solid black;
}

#studyroom .timeTalble table tr {
	border: 1px solid #333;
	text-align: center;
	width: 100%;
	height: 1.2rem;
	font-size: 12px;
	line-height: 12px;
	white-space: nowrap;
	/* overflow: hidden; */
	/* text-overflow: ellipsis; */
	font-size: 12px;
}

#studyroom .search_textarea {
	resize: none;
	margin: 20px 20px 0px 20px;
	width: 280px;
}

#studyroom .t_pointer {
	cursor: pointer;
}

#studyroom .CalendarDiv {
	text-align: center;
}

#studyroom .timeTalbleDiv {
	text-align: center;
}

#studyroom #fullscreen_view:fullscreen {
	padding: 20px;
}
#studyroom #fullscreen_view:fullscreen .timeTalbleDiv {
	text-align: center !important;
}
.only_fullscreen {
	display: none;
}
#studyroom #fullscreen_view:fullscreen .only_fullscreen {
	display: block;
}

@media (max-width: 768px) {
	#studyroom .Calendar {
		width: 100%;
	}
	#studyroom .Calendar .dates {
		height: 60px;
	}
	#studyroom .Calendar .dates > .c_date {
		width: 20px;
		height: 20px;
		font-size: 0.7rem;
		padding: 2px;
	}
	#studyroom .Calendar .dates > .b_cnt {
		width: 16px;
		height: 16px;
		padding: 10px;
		font-size: 0.8rem;
	}
	#studyroom .Calendar .dates > .c_cnt {
		width: 16px;
		height: 16px;
		padding: 10px;
		font-size: 0.8rem;
	}
	#studyroom .timeTalble {
		width: 100%;
		font-size: 0.7rem;
	}
}

@media (min-width: 1374px) {
	#studyroom .CalendarDiv {
		text-align: right;
	}
	#studyroom .timeTalbleDiv {
		text-align: left;
	}
}

@media (max-width: 767px) {
	#studyroom .Calendar .dates .c_txt {
		display: none;
	}
}
