@tailwind base;
@tailwind components;
@tailwind utilities;
/* @tailwind variants; */

@layer base {
	/* * {
		@apply dark:text-moaWhite;
	} */
	/* Bootstraptable hover event */
	.table-hover tbody tr:hover td {
		@apply bg-moa;
	}

	/* 공간/좌석 스타일 */
	tr .block span:not(.etclabel) {
		@apply dark:text-black;
	}

	tr .room span {
		@apply dark:text-moa !important;
	}

	#seat_view .block.etc_01 {
		@apply dark:bg-transparent !important;
	}
	#seat_view .block.etc_01 span.etclabel {
		@apply dark:border-white dark:border-[1px] !important;
	}
	.status_card_txt {
		@apply dark:text-moaWhite !important;
	}

	/* 공간/좌석 수정 페이지 seatsetting */
	#tools_wrap {
		@apply bg-white dark:bg-moaDark;
	}
	#tools_wrap ul.group li {
		@apply bg-[#ececec] dark:bg-transparent hover:bg-[#fcfcfc] dark:hover:bg-slate-400;
	}
	#tools_wrap ul.group li.active {
		@apply bg-[#fcfcfc] dark:bg-slate-400;
	}
	#tools_wrap ul.group li.active ol li {
		@apply bg-[#fcfcfc] dark:bg-slate-400;
	}

	#tools_wrap .window {
		@apply bg-[#fcfcfc] dark:bg-transparent;
	}

	#canvas_wrap {
		@apply bg-white dark:bg-transparent;
	}

	.panel {
		@apply bg-white  dark:bg-[#555555ad] !important;
	}
	#canvas_wrap .space .panel .body .gr table {
		@apply bg-white  dark:bg-[#555555ad] !important;
	}

	#canvas_wrap .space .panel .top {
		@apply bg-white dark:bg-transparent;
	}

	#canvas_wrap .space .panel .top input {
		@apply dark:bg-transparent;
	}

	#canvas_wrap .panel.blink button.add {
		@apply bg-[#ececec] dark:bg-transparent;
	}

	td .block:not([class^='etc']) {
		@apply bg-white dark:bg-[#555555ad];
	}
	td .block {
		display: table !important;
	}

	tr.aactive {
		@apply bg-gray-400 opacity-60;
	}

	/* BootstrapTable Style */
	.table {
		@apply dark:text-moaWhite;
	}
	.btn-group button {
		@apply dark:text-moaWhite;
	}
}

@layer components {
	.study_place_bs .react-bs-table {
		@apply dark:bg-moaDark;
	}

	.btn-group button {
		@apply dark:bg-moaBlack;
	}

	.mobile-h-screen {
		/* height: -webkit-fill-available !important; */
		@apply h-[calc(var(--vh,1px)*100)];
	}
	.grid {
		display: grid;
	}
	.soft-flex {
		display: flex;
	}

	.soft-scale {
		transform: scaleY(0);
	}

	.soft-h-0 {
		height: 0;
	}

	.soft-block {
		display: block;
	}

	.child-item-init {
		transtorm-origin: 50% top 0px;
		height: 0;
	}

	.moa-white-wrapper {
		@apply flex p-4 bg-white shadow-sm border-2 rounded-md w-full;
	}

	.btn-dark {
		@apply dark:text-moaWhite dark:border-moaWhite !important;
	}
}

#root {
	overflow: hidden;
}

@supports (-webkit-touch-callout: none) {
	.h-screen {
		height: -webkit-fill-available !important;
	}
}
html {
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	overflow: hidden;
}
* {
	/* color: #424242; */
}
body {
	min-height: -webkit-fill-available !important;
}

button,
[type='button'] {
	background-color: inherit;
}

input[type='date'] {
	@apply dark:text-moaWhite;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*  반응형 높이 적용  */
#body-wrapper {
	/* height: calc(var(--vh, 1vh) * 100) !important; */
	max-height: calc((var(--vh, 1vh) * 100) - 52px) !important;
}

html,
body,
#root {
	margin: 0;
	width: 100%;
	height: 100%;
}
.btn-info {
	background-color: #5bc0de;
	border-color: #46b8da;
}

.btn-warning {
	color: #fff;
	background-color: #f0ad4e;
	border-color: #eea236;
}
body {
	font-family: 'Barlow', sans-serif;
	overflow: auto;
}

#admin-menu div > ul {
	padding: 0;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea,
div[role='menuitem'],
ul[role='listbox'] li {
	font-size: 16px;
}

.portal-hide-scrollbars {
	-ms-overflow-style: none;
}

.portal-hide-scrollbars::-webkit-scrollbar {
	width: 0;
}

.portal-flex {
	flex: 1 1 auto;
}

/* Elements Pages */
.portal-pages__header {
	flex-direction: row;
	box-sizing: border-box;
	display: flex;
	max-height: 100%;
	align-content: center;
	justify-content: center;
	align-items: center;
	min-height: 500px;
	background: rgba(0, 0, 0, 0.05);
}

.portal-pages__header-demo {
	max-width: 400px;
	margin-top: 0;
	margin-bottom: 150px;
	padding: 0px 48px;
	display: flex;
	flex: 1 1 auto;
	position: relative;
	justify-content: center;
}

.portal-pages__content-inner {
	margin-top: -200px;
	padding: 16px;
	box-shadow: 0px -40px 100px -50px rgba(0, 0, 0, 0.3), -20px 0px 40px -50px rgba(0, 0, 0, 0.2), 20px 0px 40px -50px rgba(0, 0, 0, 0.2);
	opacity: 0;
	transform: translateY(20px);
	animation: portal-pages__content-inner 0.3s forwards 1 ease-in-out;
}

@keyframes portal-pages__content-inner {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	/* -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none; */
}

.ql-editor {
	height: 200px !important;
}

@import 'custom.css';
