.room_setting_body {
	display: block;
	padding: 20px;
	border: solid 1px #c1c1c1;
	margin-bottom: 10px;
	background: white;
	height: 480px;
}
.room_setting_body .room_setting_title {
	min-height: 40px;
	vertical-align: middle;
}
.room_setting_body .room_setting_div {
	vertical-align: middle;
	text-align: right;
}
.room_setting_body .room_setting_image {
	display: inline-block;
	overflow: hidden;
	vertical-align: top;
	background: white;
	padding-top: 20px;
	width: 100%;
}
.room_setting_body .img_layout {
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	height: 280px;
	-webkit-overflow-scrolling: touch;
}
.room_setting_body .img_first {
	width: 300px;
	height: 280px;
	display: inline-block;
	margin: 0px 10px;
	vertical-align: bottom;
	text-align: center;
}
.room_setting_body .img_next {
	width: 300px;
	height: 280px;
	display: inline-block;
	margin: 0px 10px;
	vertical-align: middle;
}
.room_setting_body .img_responsive {
	/* display: block */
	max-width: 100%;
	height: 100%;
	object-fit: fill;
}
.room_setting_body .img_upload:hover {
	cursor: pointer;
}
.room_setting_body .deleteBtn {
	height: 26px;
	padding: 0 8px;
	top: 8px;
	right: 8px;
	position: relative;
	float: right;
	background: #fafafa;
	border: 1px solid #f50057;
	border-radius: 24px;
}
.room_setting_body .deleteIcon {
	width: 14px;
	height: 14px;
	display: inline-block;
	vertical-align: middle;
}
.room_setting_body .deleteText {
	display: inline-block;
	font-size: 0.7rem;
	margin: 0 0 0 4px;
	vertical-align: middle;
	padding-top: 0;
	color: #f50057;
}
.room_setting_body .deleteBtn:hover {
	cursor: pointer;
	opacity: 1;
}

@media (max-width: 767px) {
	.room_setting_body {
		height: 880px;
	}
	.room_setting_body .room_setting_div {
		margin-top: 6px;
	}
	.room_setting_body .img_layout {
		height: 300px;
	}
	.room_setting_body .img_responsive {
		max-height: 300px;
	}
	.room_setting_body .img_room {
		width: 100%;
	}
	.room_setting_body .deleteRoomBtn {
		float: right;
	}
}
@media (min-width: 768px) and (max-width: 1024px) {
	.room_setting_body {
		height: 920px;
	}
}
