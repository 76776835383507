.slider-dots {
  padding: 0;
  margin: 0;
  display: flex !important;
  justify-content: center;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 8px;
  & .slick-active {
    & button {
      background-color: white;
    }
  }

  & li {
    list-style: none;
    display: flex;
    width: 8px;
    height: 8px;
    margin : 0px 5px ;
    & button {
      padding: 0;
      margin : 0;
      background-color: gray;
      width: 100%;
      height: 100%;
      border : none;
      border-radius: 50%;
      transition : all 0.2s ease-in-out;
      &:hover {
       background-color: white;
       opacity: 0.8;
      }
    }
  }

  // & li:first-child{
  //   & button{
  //     border-top-left-radius: 1rem;
  //     border-bottom-left-radius: 1rem;
  //   }
  // }
  // & li:last-child{
  //   & button{
  //     border-top-right-radius: 1rem;
  //     border-bottom-right-radius: 1rem;
  //   }
  // }
}
.slick-slider {
  .slick-next {

    opacity: 0;
    transition : opacity 0.2s ease-in-out;
    &::before {
      color : #576574;
    }
  }
  .slick-prev {

    opacity: 0;
    transition : opacity 0.2s ease-in-out;
    &::before {
      color :#576574;
    }
  }
  &:hover {
    .slick-next {
      opacity: 1;
    }

    .slick-prev {
      opacity: 1;
      z-index: 10000;
    }
  }
}

// .deleteBtn{
//   height: 26px;
// 	padding: 0 8px;
// 	top: 8px;
// 	right: 8px;
// 	position: relative;
// 	float: right;
// 	background: #fafafa;
// 	border: 1px solid #f50057;
// 	border-radius: 24px;
//   cursor: pointer;
// }
// .deleteIcon {
//   width: 14px;
// 	height: 14px;
// 	display: inline-block;
// 	vertical-align: middle;
// }

// .deleteText {
//   display: inline-block;
// 	font-size: 0.7rem;
// 	margin: 0 0 0 4px;
// 	vertical-align: middle;
// 	padding-top: 0;
// 	color: #f50057;
// }
